/* eslint-disable */
import React, { lazy /* , useEffect */ } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import MainLogo from "../components/MainLogo/MainLogo";
import Cookies from "js-cookie";
import CleverLoader from "../components/CleverLoader/CleverLoader"
// import setPassword from '../screens/Auth/SetPassword/SetPassword';

const Auth = lazy(() => import("../screens/Auth"));
const SetEmail = lazy(() => import("../screens/Auth/setEmail/SetEmail"));
// const Login = lazy(() => import('../screens/Auth/Login'));
const SetPassword = lazy(() => import("../screens/Auth/SetPassword"));
const AdminImitatorLogin = lazy(() =>
  import('../screens/Auth/AdminImitatorLogin/AdminImitatorLogin'),
);
const CreateAccount = lazy(() => import("../screens/Auth/CreateAccount"));
const ForgotPassword = lazy(() => import("../screens/Auth/ForgotPassword"));
const VerifyPassword = lazy(() => import("../screens/Auth/VerifyPassword"));
const VerifyAccount = lazy(() => import("../screens/Auth/VerifyAccount"));
const CreatePassword = lazy(() => import("../screens/Auth/CreatePassword"));
const CreateAccountPassword = lazy(() =>
  import("../screens/Auth/CreateAccountPassword")
);
const CreateOtherInfo = lazy(() => import("../screens/Auth/CreateOtherInfo"));
const QuestLogin = lazy(() => import("../screens/Auth/QuestLogin"));
const StudentApplicationForm = lazy(() =>
  import("../screens/Main/v1/StudentApplicationForm")
);
const FacilitatorApplicationForm = lazy(() => import("../2.0/FacilitatorApplication/FacilitatorApplicationForm"))
const StudentFormSuccess = lazy(() =>
  import("../screens/Main/v1/StudentApplicationForm/Success")
);
const LoginViaSalesforce = lazy(() =>
  import("../screens/Auth/LoginViaSalesforce")
);
const LicenseManagement = lazy(() =>
  import("../screens/Main/v1/LicenseManagement/MainPage")
);

const LicenseManagementViaProjectId = lazy(() =>
  import("../screens/Main/v1/LicenseManagement/LicenseManagementViaProjectId")
);
const Main = lazy(() => import("../screens/Main"));

const PublicRoute = ({ as: Component, ...props }) => {
  // const [cookies] = useCookies();
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(false);
  const [salesforceAuth, setSalesforceAuth] = React.useState(false);
  const [cleverCode, setCleverCode] = React.useState();
  const [licenseAuth, setLicenseAuth] = React.useState(false);
  const [token, setToken] = React.useState();

  const paramStart = window.location.href.indexOf("?");
  const paramSearchString = window.location.href.substr(paramStart);
  const urlParams = new URLSearchParams(paramSearchString);
  const cleverCodeParam = urlParams.get('code');
  const tokenParam = urlParams.get('token')

  // If we have userId already and in a scenario where the code was provide in the url
  // reload the url to the state without the code to preven unnecessary clever logic
  if (Cookies.get("userId") && Boolean(cleverCodeParam)) {
    const urlBeforeParams = window.location.href.split('?')[0];
    window.location = urlBeforeParams;
  }

  console.log(
    "0TEST",
    window.location.href.indexOf("/licenseManagementViaProjectId"),
    window.location.href.indexOf("/facilitator-application-form"),
    window.location.href,
    window.location.pathname
  );
  React.useEffect(() => {
    console.log(
      "TEST",
      window.location.href.indexOf("/licenseManagementViaProjectId"),
      window.location.href.indexOf("/facilitator-application-form"),
      window.location.href,
      window.location.pathname
    );
    if (
      Cookies.get("userId") &&
      window.location.href.indexOf("/loginViaSalesforce?") === -1 &&
      window.location.href.indexOf("/licenseManagementViaProjectId") === -1 &&
      window.location.href.indexOf("/facilitator-application-form") === -1
    ) {
      // navigate(`app/dashboard/${Cookies.get('School__c__ID')}`);
      navigate(`landing-page`);
    } else if (Boolean(cleverCodeParam)) {
      setCleverCode(cleverCodeParam);
    } else if (window.location.href.indexOf("/loginViaSalesforce?") !== -1) {
      setSalesforceAuth(true);
    } else if (
      window.location.href.indexOf("/licenseManagementViaProjectId") !== -1
    ) {
      setLicenseAuth(true);
    } else {
      setAuth(false);
    }
  }, [navigate]);

  return salesforceAuth ? (
    <LoginViaSalesforce />
  ) : Boolean(cleverCode) ?
    <CleverLoader cleverCode={cleverCode} />
    : licenseAuth ? (
    <LicenseManagementViaProjectId />
  ) : (
    <Component {...props} />
  );
};

const authNav = [
  {
    path: "",
    element: <SetEmail />,
  },
  {
    path: "forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "createAccount",
    element: <CreateAccount />,
  },
  {
    path: "password",
    element: <SetPassword />,
  },
  {
    path: "password/:userEmailPort",
    element: <SetPassword />,
  },
  {
    path: "createpassword",
    element: <CreatePassword />,
  },
  {
    path: "createaccountpassword",
    element: <CreateAccountPassword />,
  },
  {
    path: "createOtherInfo",
    element: <CreateOtherInfo />,
  },
  {
    path: "verifytoken/:token/:Id",
    element: <VerifyPassword />,
  },
  {
    path: "verify-account/:token/:Id",
    element: <VerifyAccount />,
  },
  {
    path: "login",
    element: <SetEmail />,
  },
  {
    path: "qlogin",
    element: <QuestLogin />,
  },
  {
    path: "student-application-form/success",
    element: <StudentFormSuccess />,
  },
  {
    path: "student-application-form/:schoolId",
    element: <StudentApplicationForm />,
  },
  {
    path: 'facilitator-application-form/:token',
    element: <FacilitatorApplicationForm />,
  },
  {
    name: "License Management",
    path: "",
    element: <Main />,
    children: [
      {
        path: "loginViaSalesforceforLicense",
        element: <LicenseManagement />,
      },
    ],
  },
  {
    name: "License Management",
    path: "",
    element: <Main />,
    children: [
      {
        path: "licenseManagementViaProjectId/:projectId",
        element: <LicenseManagementViaProjectId />,
      },
    ],
  },
  {
    path: 'adminImitatorLogin',
    element: <AdminImitatorLogin />,
  }
];

const authRoutes = [
  {
    path: "/",
    element: <PublicRoute as={Auth} />,
    children: authNav,
  },
];

export default authRoutes;
